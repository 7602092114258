import React from 'react'
import { DateTime } from 'luxon'
import { navigate } from 'gatsby'
import { CircularProgress, Modal, Section, Button } from '../components/library'
import { SliderField } from '../components/library/entitysync'
import { ImpactContext } from '../components/Layout'
import GoalDescription from '../components/GoalDescription'
import getGoalTitle from '../utils/getGoalTitle'
import { Entity, SubmitButtonComponent } from '@parallelpublicworks/entitysync'
import goalCheckInMarks from '../utils/goalCheckInMarks'
import setBonesToBeAdded from '../utils/setBonesToBeAdded'

const SubmitButton = SubmitButtonComponent(props => {
  return <Button onClick={props.onClick} {...props} />
})

function GoalCheckInPage({ location }) {
  const { response, backTo } = location.state
  const [loading, setLoading] = React.useState(false)
  const { userData, profiles, currentWeek, previousWeek } = React.useContext(ImpactContext)
  const [source, setSource] = React.useState(null)
  const [nodeType, setNodeType] = React.useState(null)
  const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
  const [boneID, setBoneID] = React.useState(null)
  const [goingAway, setGoingAway] = React.useState(false)

  const marks = goalCheckInMarks.map(mrk => {
    let color
    let icon
    switch (mrk.level) {
      case 4:
        color = 'great'
        icon = 'good'
        break
      case 2:
        color = 'neutral'
        icon = 'neutral'
        break
      default:
        color = 'danger'
        icon = 'bad'
        break
    }
    return {
      value: mrk.value,
      label: (
        <>
          <i className={`icon-mood-${icon} ${color}`} />
          <span>{mrk.label}</span>
        </>
      ),
      color
    }
  })

  React.useEffect(() => {
    if (typeof response !== 'undefined') {
      setNodeType(response.type)
      setSource(response)
    } else {
      setNodeType('node--goal')
      setSource({})
    }
    setBoneID(DateTime.now().toISO())
  }, [])

  React.useEffect(() => {
    if (loading) {
      setUserDataChangesCount(userDataChangesCount + 1)
    }
  }, [userData, loading])

  React.useEffect(() => {
    if (userDataChangesCount >= 3 && !goingAway) {
      setGoingAway(true)
      const bones_to_add = previousWeek && previousWeek.todo_done ? 3 : 2
      // console.warn('bones_to_add after goal checkin', bones_to_add, 'because', previousWeek?.todo_done);
      setBonesToBeAdded(bones_to_add, boneID, 'Great job completing your goal! Keep it up!', false)
      navigate(backTo)
    }
  }, [userDataChangesCount])

  function onSubmit(e, unsavedChanges) {
    setLoading(true)
  }

  if (currentWeek === null || profiles === null || nodeType === null || source === null) {
    return <CircularProgress />
  }

  return (
    <Entity type={nodeType} source={source} componentId="goal-check-in-entity">
      <Modal
        id="goal-check-in"
        open={true}
        title="Goal Check-In"
        subtitle={`IMPACT Week ${currentWeek && currentWeek.number + 1}, ${currentWeek.label}`}
        size="sm"
        profiles={[profiles.child, profiles.parent]}
        handleClose={e => navigate(-1)}
        disabled={loading}
        footer={
          <SubmitButton variant="contained" onSubmit={onSubmit} disabled={loading} color="primary">
            Submit
          </SubmitButton>
        }
      >
        <Section title={getGoalTitle(response)}>
          <GoalDescription goal={response} />
        </Section>

        <SliderField field="field_check_in_value" marks={marks} label="How did it go?" disabled={loading} hasIcons />
      </Modal>
    </Entity>
  )
}

export default GoalCheckInPage
